#html-body .section-new-products,
#html-body .section-best-seller,
#html-body .section-featured-seller {
    padding: 70px 0px;
    width: 100%;
}

#html-body .section-how-to-shop {
    padding-left: 0;
    padding-right: 0;
}
@media(max-width: 1280px) and (min-width: 1024px) {
    #html-body .section-how-to-shop {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px !important; 
    }
}
@media(max-width: 1023px) and (min-width: 768px) {
    #html-body .section-how-to-shop {
        padding-bottom: 30px !important; 
    }
}

@media (max-width: 767px) {
    div.section-product-highlight .slick-list .slick-track > .slick-slide:not(:first-child) {
        background-color: transparent !important;
        background: unset;
    }
    #html-body div.section-product-highlight .pagebuilder-column-group:last-child .pagebuilder-column.product-column {
        padding-left: 48%;
        padding-top: 2%;
        padding-bottom: 2%;
        width: 100%;
    }
    #html-body div.section-product-highlight .pagebuilder-column-group:last-child .pagebuilder-column.product-column .widget-product-list {
        margin-right: -10px;
        width: auto;
    }
    #html-body div.section-product-highlight .pagebuilder-column-group:last-child .pagebuilder-column.banner-column {
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translate(0,-50%);
        width: 100vw !important;
        max-height: 100%;
        overflow: hidden;
        border-radius: 0;
    }
    #html-body div.section-product-highlight .pagebuilder-column-group:last-child .pagebuilder-column.banner-column figure {
        line-height: 0;
    }
    #html-body div.section-product-highlight .pagebuilder-column-group:last-child .pagebuilder-column.banner-column figure img {
        border-radius: 0;
    }
    div.section-product-highlight .slick-list .slick-track > .slick-slide:not(:first-child) {
        background-color: transparent !important;
    }
    div.pagebuilder-column-group {
        position: relative;
    }
    #html-body div.section-product-highlight .pagebuilder-column-group:first-child .pagebuilder-column {
        display: flex;
        justify-content: space-between;
    }
    #html-body div.section-product-highlight .pagebuilder-column-group:first-child .pagebuilder-column h3 {
        line-height: normal;
        font-size: 18px;
    }
    .section-product-highlight .slick-list {
        padding-left: 0 !important;
        /* display: flex; */
    }
    .section-product-highlight .slick-list .slick-track {
        height: auto !important;
    }
    .section-product-highlight .slick-list .slick-track > .slick-slide {
        padding-top: 0;
        height: 100%;
    }
    .section-product-highlight .slick-list .slick-track > .slick-slide > div{
        height: 100%;
    }
    .section-product-highlight .slick-list .slick-track > .slick-slide > div > div > div > div:nth-of-type(2) {
        padding: 10px 0 0;
    }
    .section-product-highlight .slick-slider .slick-list .item-product > div {
        max-height: 260px;
    }
    #html-body .section-product-highlight .widget-product-list .carousel div.slick-list div.slick-track .slick-slide > div {
        width: 100% !important;
    }
    .section-product-highlight .slick-slider .slick-list .item-product > div {
        min-height: 242px;
    }
}

@media(max-width: 374px) and (min-width: 320px) {
    #html-body div.section-product-highlight .pagebuilder-column-group:last-child .pagebuilder-column.product-column {
        margin-left: 5px;
    }
}
@media(max-width: 767px) and (min-width: 425px) {
    #html-body div.section-product-highlight .pagebuilder-column-group:last-child .pagebuilder-column.product-column {
        padding-left: 55% !important;
        margin-left: -70px;
    }
}

@media(min-width: 768px) and (max-width: 1280px) {
    #layout {
        width: 100%;
    }
    #html-body div.section-product-highlight .pagebuilder-column-group:last-child .pagebuilder-column.product-column {
        /* padding-left: 200px; */
        margin-left: 0;
    }
    #html-body div.section-product-highlight .pagebuilder-column-group:last-child .pagebuilder-column.banner-column {
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
    }
    div.section-product-highlight .slick-list .slick-track > .slick-slide:not(:first-child) {
        background-color: #FFFFFF !important;
    }
    div.pagebuilder-column-group {
        position: relative;
    }
}
@media(min-width: 1199px) and (max-width: 1280px) {
    #html-body div.section-product-highlight .pagebuilder-column-group {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .section-product-highlight .slick-list .slick-track > .slick-slide {
        padding-top: 20px;
    }
    .section-product-highlight .slick-list .slick-track > .slick-slide > div{
        height: 100%;
    }
    .section-product-highlight .slick-slider .slick-list .item-product > div {
        max-height: 300px;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 1024px) {
    .section-product-highlight .slick-list .slick-track > .slick-slide {
        padding-top: 0;
    }
    .section-product-highlight .slick-list .slick-track > .slick-slide > div{
        height: 100%;
    }
}

@media (min-width: 768px) {
    #html-body .section-product-highlight .pagebuilder-column-group:last-child .pagebuilder-column.product-column {
        width: 100% !important;
        margin-left: -280px;
    }
}

@media (min-width: 1200px) {
    .section-product-highlight .slick-list .slick-track > .slick-slide {
        padding-top: 25px;
    }
    .section-product-highlight .slick-slider .slick-list .item-product > div {
        min-height: 280px;
    }
}

@media (min-width: 768px) {
    .section-product-highlight .slick-list div.slick-track {
        margin-left: 210px;
    }
}

#html-body .section-how-to-shop .row-full-width-inner>h2,
#html-body .section-new-products .row-full-width-inner>h2,
#html-body .section-best-seller .row-full-width-inner>h2,
#html-body .section-featured-seller .row-full-width-inner>h2 {
    font-size: 25px;
    font-weight: 700;
}

#html-body .section-how-to-shop .row-full-width-inner>h2,
#html-body .section-new-products .row-full-width-inner>h2,
#html-body .section-best-seller .row-full-width-inner>h2 {
    margin: 0 0 30px;
}

#html-body .section-featured-seller .row-full-width-inner>h2 {
    margin: 0 auto !important;
}

.MuiAutocomplete-inputRoot{
    padding: 5px 40px 5px 50px;
}

.custom-button-switcher::after {
    content: '\e967';
    font-family: 'icomoon' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #FFFFFF;
    font-size: 4px;
    display: inline-block;
    margin-left: 5px;
}

#html-body .section-new-products .row-full-width-inner,
#html-body .section-best-seller .row-full-width-inner {
    padding-left: 60px;
    padding-right: 60px;
}

#html-body .section-banner-slider .pagebuilder-slide-wrapper .pagebuilder-overlay {
    max-width: 1280px;
    margin: auto;
}

#html-body .section-banner-slider .pagebuilder-slider .slick-prev,
#html-body .section-banner-slider .pagebuilder-slider .slick-next {
    height: 28px;
    width: 28px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.25);
}

#html-body .section-banner-slider .pagebuilder-slider .slick-prev::before,
#html-body .section-banner-slider .pagebuilder-slider .slick-next::before {
    color: var(--biofarma-main-white);
    font-size: 18px;
    opacity: 1;
}

#html-body .section-banner-slider .pagebuilder-slider .slick-dots {
    position: absolute;
    bottom: 0;
    padding: 20px 0;
}

#html-body .section-banner-slider .pagebuilder-slider .slick-dots li {
    margin: 0 3px;
}

#html-body .section-banner-slider .pagebuilder-slider .slick-dots li button {
    width: 10px;
    height: 10px;
}

#html-body .section-banner-slider .pagebuilder-slider .slick-dots li.slick-active button {
    background: var(--biofarma-main-orange);
    border-color: var(--biofarma-main-white);
}

#html-body .section-banner-slider .pagebuilder-slider .slick-list .slick-slide .banner-style .pagebuilder-slide-wrapper .pagebuilder-overlay {
    padding: 20px;
}

#html-body .section-banner-slider .pagebuilder-slider .slick-list .slick-slide .banner-style .pagebuilder-slide-wrapper .pagebuilder-overlay .pagebuilder-poster-content>div {
    max-width: 50%;
    overflow: unset;
}

#html-body .section-banner-slider .pagebuilder-slider .slick-list .slick-slide .banner-style .pagebuilder-slide-wrapper .pagebuilder-overlay .pagebuilder-poster-content>div p {
    line-height: normal;
}

#html-body .section-banner-slider .pagebuilder-slider .slick-list .slick-slide .banner-style .pagebuilder-slide-wrapper .pagebuilder-overlay .pagebuilder-poster-content>div p:first-child {
    margin-bottom: 20px;
}

#html-body .section-banner-slider .pagebuilder-slider .slick-list .slick-slide .banner-style .pagebuilder-slide-wrapper .pagebuilder-overlay .pagebuilder-poster-content>div p:first-child span {
    font-size: 80px;
}

#html-body .section-banner-slider .pagebuilder-slider .slick-list .slick-slide .banner-style .pagebuilder-slide-wrapper .pagebuilder-overlay .pagebuilder-poster-content>div p:not(:first-child) span {
    font-size: 18px !important;
    font-weight: 500;
    line-height: 24px;
}

#html-body .section-how-to-shop {
    background: none;
    max-width: 1280px !important;
    margin: auto;
}

#html-body .section-how-to-shop .row-full-width-inner > .pagebuilder-column-group:first-child {
    margin-bottom: 10px;
}

#html-body .section-how-to-shop .row-full-width-inner > .pagebuilder-column-group:last-child {
    box-shadow: 0 0 4px rgb(0,0,0,0.2);
    border-radius: 15px;
}

#html-body .section-how-to-shop .row-full-width-inner .pagebuilder-column-group .pagebuilder-column {
    padding: 10px 30px;
    text-align: center;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

#html-body .section-how-to-shop .row-full-width-inner > .pagebuilder-column-group:last-child > .pagebuilder-column:not(:last-child) {
    border-right: 1px solid #E1E7EB;
}

#html-body .section-how-to-shop .row-full-width-inner .pagebuilder-column-group .pagebuilder-column .icon {
    font-size: 48px;
    box-sizing: border-box;
    position: relative;
}

#html-body .section-how-to-shop .row-full-width-inner .pagebuilder-column-group .pagebuilder-column h3 {
    font-size: 16px;
    color: var(--biofarma-main-blue-darker);
    font-weight: 500;
}

#html-body .section-how-to-shop .row-full-width-inner .pagebuilder-column-group .pagebuilder-column p {
    font-size: 16px;
    text-align: center;
}

#html-body .section-how-to-shop .pagebuilder-button-primary {
    text-transform: none;
    font-size: 14px;
    padding: 0 25px;
    height: 39px;
    font-weight: 400;
}

#html-body .section-how-to-shop .row-full-width-inner > .pagebuilder-column-group:first-child > .pagebuilder-column:first-child,
#html-body .section-how-to-shop .row-full-width-inner > .pagebuilder-column-group:first-child > .pagebuilder-column:last-child {
    align-items: flex-end;
}
@media(min-width: 768px) and (max-width: 1023px) {
    #html-body .section-how-to-shop .row-full-width-inner > .pagebuilder-column-group:first-child > .pagebuilder-column:first-child {
        width: calc(100% - 250px);
        align-items: center;
    }
    #html-body .section-how-to-shop .row-full-width-inner > .pagebuilder-column-group:first-child > .pagebuilder-column:last-child {
        width: 250px;
    }
}

#html-body .section-how-to-shop .row-full-width-inner > .pagebuilder-column-group:first-child > .pagebuilder-column:first-child {
    justify-content: start;
    padding-left: 0;
}

#html-body .section-how-to-shop .row-full-width-inner > .pagebuilder-column-group:first-child > .pagebuilder-column:first-child h2 {
    font-weight: bold;
    font-size: 25px;
}

#html-body .section-how-to-shop .row-full-width-inner > .pagebuilder-column-group:first-child > .pagebuilder-column:last-child {
    justify-content: end;
    padding-right: 0;
}

/* #html-body .section-how-to-shop .row-full-width-inner .pagebuilder-column-group .pagebuilder-column .icon::after {
    content: '';
    font-size: 14px;
    height: 28px;
    width: 28px;
    position: absolute;
    background: var(--biofarma-main-blue-darker);
    color: var(--biofarma-main-white);
    font-family: 'Roboto';
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    top: 10px;
    margin-left: -12px;
    font-weight: bold;
}

#html-body .section-how-to-shop .row-full-width-inner .pagebuilder-column-group .pagebuilder-column .icon.icon-register::after {
    content: '1';
}

#html-body .section-how-to-shop .row-full-width-inner .pagebuilder-column-group .pagebuilder-column .icon.icon-store::after {
    content: '2';
}

#html-body .section-how-to-shop .row-full-width-inner .pagebuilder-column-group .pagebuilder-column .icon.icon-shop::after {
    content: '3';
} */

#html-body .section-shop-by{
    padding: 30px !important;
    width: 100vw;
    display: flex;
    align-items: center;
    background: var(--biofarma-main-lighter-blue);
}
@media(max-width: 1280px) and (min-width: 768px) {
    #html-body .section-shop-by,
    #html-body .section-information.join-distributor {
        padding: 30px 20px !important; 
    }
}
@media(max-width: 767px) {
    #html-body .section-information.join-distributor {
        padding: 0 20px !important; 
    }
}

#html-body .section-information.join-distributor {
    padding: 30px;
    width: 100vw;
    max-width: none;
    align-items: center;
}

#html-body .section-shop-by .pagebuilder-column-group,
#html-body .section-information.join-distributor .pagebuilder-column-group {
    gap: 16px;
    max-width: 1280px !important;
    width: 100%;

}

#html-body .section-shop-by .pagebuilder-column-group .pagebuilder-button-primary {
    background: white;
    color: var(--biofarma-main-orange);
    border: 2px solid var(--biofarma-main-orange);
    box-shadow: none;
    text-transform: none;
}

#html-body .section-shop-by .pagebuilder-column.left-wrapper,
#html-body .section-shop-by .pagebuilder-column.right-wrapper,
#html-body .section-information.join-distributor .pagebuilder-column.left-wrapper,
#html-body .section-information.join-distributor .pagebuilder-column.right-wrapper {
    border-radius: 15px;
    display: flex;
    padding: 40px;
    min-height: 0 !important;
    width: 100%;
}

#html-body .section-shop-by .pagebuilder-column h2 {
    font-size: 40px;
    font-weight: 700;
    margin: 0 0 20px;
    color: var(--biofarma-main-blue-darker);
    word-wrap: normal;
    line-height: 1.1em;
    max-width: 320px !important;
}

#html-body .section-shop-by .pagebuilder-column p {
    font-size: 18px;
    margin-bottom: 20px;
}

#html-body .section-exclusive-offers {
    border-radius: 25px;
    justify-content: center !important;
    max-width: 1280px;
    margin: auto;
}

#html-body .section-exclusive-offers>.pagebuilder-column-group .pagebuilder-column {
    max-width: 570px;
    margin: auto;
    min-height: 0 !important;
    padding: 30px 35px;
    border-radius: 15px;
    
}

#html-body .section-exclusive-offers>.pagebuilder-column-group .pagebuilder-column h2 {
    font-size: 50px;
    margin: 0 0 10px;
    color: var(--biofarma-main-blue-darker);
}

#html-body .section-exclusive-offers>.pagebuilder-column-group .pagebuilder-column p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
}

#html-body .section-join-seller {
    max-width: 1280px;
    margin: 0 auto;
}

#html-body .section-join-seller .row-full-width-inner .pagebuilder-column-group .pagebuilder-column:first-child {
    background: var(--biofarma-main-blue-darker);
    border-radius: 25px 0 0 25px;
}

#html-body .section-join-seller .row-full-width-inner .pagebuilder-column-group .pagebuilder-column:not(:first-child) {
    border-radius: 0 25px 25px 0;
}

#html-body .section-join-seller .row-full-width-inner .pagebuilder-column-group .pagebuilder-column h2,
#html-body .section-join-seller .row-full-width-inner .pagebuilder-column-group .pagebuilder-column p {
    color: var(--biofarma-main-white);
}

#html-body .section-join-seller .row-full-width-inner .pagebuilder-column-group .pagebuilder-column h2 {
    font-size: 50px;
    margin: 0 0 10px;
    font-weight: 500;
}

#html-body .section-join-seller .row-full-width-inner .pagebuilder-column-group .pagebuilder-column p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
}

#html-body .section-join-seller .row-full-width-inner .pagebuilder-button-primary {
    color:white;
    background: var(--biofarma-main-orange);
    box-shadow: none;
    text-transform: none;   
}

#html-body .section-join-seller .row-full-width-inner .pagebuilder-button-primary:hover,
#html-body .section-join-seller .row-full-width-inner .pagebuilder-button-primary:focus,
#html-body .section-join-seller .row-full-width-inner .pagebuilder-button-primary:active {
    background: var(--biofarma-main-orange);
    /* border-color: var(--biofarma-main-white); */
    opacity: 0.8;
}

#html-body .section-featured-seller {
    padding-bottom: 0;
}

#html-body .section-featured-seller .pagebuilder-column-group {
    max-width: 860px;
    margin: auto;
    text-align: center;
}

#html-body .section-featured-seller .pagebuilder-column-group .pagebuilder-column img {
    max-width: 220px !important;
}

#html-body .pagebuilder-button-primary {
    height: 40px;
    padding: 0 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    background: var(--biofarma-main-orange);
    border-color: var(--biofarma-main-orange);
    transition: all 0.15s ease-in;
    color: var(--biofarma-main-white);
}

#html-body .pagebuilder-button-primary:hover {
    background: var(--biofarma-main-orange);
    border-color: var(--biofarma-main-orange);
    opacity: 0.8;
}

#html-body .block-new-products {
    padding: 70px 60px 30px;
}

#html-body .block-new-products .block-title {
    margin-bottom: 30px;
    text-align: center;
}

#html-body .block-new-products .block-title strong {
    font-size: 40px;
    font-weight: bold;
}

#html-body .block-new-products .block-content .products-grid .product-items {
    position: relative;
}

#html-body .block-new-products .block-content .products-grid .product-items .owl-stage {
    display: flex;
}

#html-body .block-new-products .block-content .products-grid .product-items .owl-stage .owl-item {
    display: flex;
}

#html-body .block-new-products .block-content .products-grid .product-items .product-item {
    width: 100%;
    margin-bottom: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

#html-body .block-new-products .block-content .products-grid .product-items .product-item .product-item-info .product-item-details {
    padding: 0 10px;
}

#html-body .block-new-products .block-content .products-grid .product-items .product-item .product-item-info .vendor-code-label {
    font-size: 12px;
    font-weight: bold;
    line-height: 19px;
}

#html-body .block-new-products .block-content .products-grid .product-items .product-item .product-item-info .product-item-name {
    padding-bottom: 10px;
}

#html-body .block-new-products .block-content .products-grid .product-items .product-item .product-item-info .product-item-name a {
    font-size: 14px;
    line-height: 20px;
}

#html-body .block-new-products .block-content .products-grid .product-items .product-item .product-item-info:hover,
#html-body .block-new-products .block-content .products-grid .product-items .product-item .product-item-info.active {
    box-shadow: none;
    border: none;
    margin: 0;
    padding: 0;
}

#html-body .block-new-products .block-content .products-grid .product-items .product-item .product-item-info .price-box,
#html-body .block-new-products .block-content .products-grid .product-items .product-item .product-item-info .product-item-actions {
    display: none;
}

#html-body .block-new-products .block-content .products-grid .product-items .owl-nav .owl-prev,
#html-body .block-new-products .block-content .products-grid .product-items .owl-nav .owl-next {
    position: absolute;
    background: #fff;
    box-shadow: 0 4px 14px -5px rgba(0, 0, 0, 0.5);
    width: 30px;
    height: 30px;
    border-radius: 50px;
    top: 35%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

#html-body .block-new-products .block-content .products-grid .product-items .owl-nav .owl-prev span:before,
#html-body .block-new-products .block-content .products-grid .product-items .owl-nav .owl-next span:before {
    content: '\e622';
    font-family: 'luma-icons';
    display: block;
    color: var(--biofarma-main-blue-darker);
    font-size: 12px;
    line-height: 30px;
    font-weight: bold;
}

#html-body .block-new-products .block-content .products-grid .product-items .owl-nav .owl-prev {
    left: -20px;
}

#html-body .block-new-products .block-content .products-grid .product-items .owl-nav .owl-prev span:before {
    transform: rotate(90deg);
}

#html-body .block-new-products .block-content .products-grid .product-items .owl-nav .owl-next {
    right: -20px;
}

#html-body .block-new-products .block-content .products-grid .product-items .owl-nav .owl-next span:before {
    transform: rotate(-90deg);
}

#html-body .section-best-seller .row-full-width-inner {
    padding-left: 10px;
    padding-right: 10px;
}

#html-body .section-best-seller .row-full-width-inner .block-products-list .products-grid .product-items {
    display: grid;
    justify-items: start;
}

#html-body .section-best-seller .row-full-width-inner .block-products-list .products-grid .product-items .product-item {
    display: flex;
    width: 100%;
    padding: 8px;
    margin-left: 0;
    margin-bottom: 0;
}

#html-body .section-best-seller .row-full-width-inner .block-products-list .products-grid .product-items .product-item .product-item-info {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15) !important;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15) !important;
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px;
    width: 100%;
}

#html-body .section-best-seller .row-full-width-inner .block-products-list .products-grid .product-items .product-item .product-item-info .product-item-photo .product-image-container .product-image-wrapper img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

#html-body .section-best-seller .row-full-width-inner .block-products-list .products-grid .product-items .product-item .product-item-info .product-item-details {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
}

#html-body .section-best-seller .row-full-width-inner .block-products-list .products-grid .product-items .product-item .product-item-info .product-item-details .product-vendor-name {
    font-size: 12px;
    font-weight: bold;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

#html-body .section-best-seller .row-full-width-inner .block-products-list .products-grid .product-items .product-item .product-item-info .product-item-details .product-item-name {
    margin: 0;
}

#html-body .section-best-seller .row-full-width-inner .block-products-list .products-grid .product-items .product-item .product-item-info .product-item-details .product-item-name a {
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

#html-body .section-best-seller .row-full-width-inner .block-products-list .products-grid .product-items .product-item .product-item-info:hover,
#html-body .section-best-seller .row-full-width-inner .block-products-list .products-grid .product-items .product-item .product-item-info.active {
    box-shadow: none;
    border: none;
    margin: 0;
    padding: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

@media (max-width: 1024px) {
    div#html-body .section-how-to-shop {
        padding: 0 20px;
    }
    div#html-body .section-shop-by .pagebuilder-column.left-wrapper {
        align-items: flex-start;
    }
    div#html-body .section-shop-by .pagebuilder-column.left-wrapper>*,
    div#html-body .section-shop-by .pagebuilder-column.right-wrapper>* {
        display: block;
        max-width: 640px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
    div#html-body .section-shop-by .pagebuilder-column.right-wrapper>h2,
    div#html-body .section-shop-by .pagebuilder-column.left-wrapper>h2 {
        font-size: 3rem;
        line-height: 1.2;
    }
    #html-body .section-exclusive-offers {
        margin: 0 20px;
    }
    div#html-body .section-join-seller {
        padding-right: 20px;
        padding-left: 20px;
    }
}

@media (max-width: 992px) {
    div#html-body .section-shop-by .pagebuilder-column-group,
    #html-body .section-information.join-distributor .pagebuilder-column-group {
        flex-wrap: wrap;
    }
    div#html-body .section-shop-by .pagebuilder-column-group .pagebuilder-column {
        width: 100%;
        min-width: 0 !important;
    }
    div#html-body .section-how-to-shop .row-full-width-inner .pagebuilder-column-group .pagebuilder-column {
        padding: 10px 30px;
    }
    div#html-body .section-join-seller .row-full-width-inner .pagebuilder-column-group {
        flex-wrap: wrap;
        border-radius: 25px;
        overflow: hidden;
    }
    div#html-body .section-join-seller .row-full-width-inner .pagebuilder-column-group .pagebuilder-column {
        width: 100%;
        border-radius: 0px !important;
    }
}

@media (max-width: 767px) {
    div#html-body .section-how-to-shop,
    div#html-body .section-new-products,
    div#html-body .section-featured-seller {
        padding: 70px 0;
    }

    div#html-body .section-how-to-shop .row-full-width-inner>h2,
    div#html-body .section-new-products .row-full-width-inner>h2,
    div#html-body .section-featured-seller .row-full-width-inner>h2 {
        font-size: 18px !important;
        margin: 0 0 24px !important;
    }

    div#html-body .section-banner-slider .pagebuilder-slide-wrapper {
        background-position: center !important;
    }

    div#html-body .section-banner-slider .pagebuilder-slide-wrapper .pagebuilder-overlay {
        max-width: 1280px;
        margin: auto;
    }

    div#html-body .section-banner-slider .pagebuilder-slider .slick-list .slick-slide .banner-style .pagebuilder-slide-wrapper {
        min-height: 360px !important;
    }

    div#html-body .section-banner-slider .pagebuilder-slider .slick-list .slick-slide .banner-style .pagebuilder-slide-wrapper .pagebuilder-overlay {
        padding: 20px 50px !important;
        text-align: center;
        min-height: 360px !important;
    }

    div#html-body .section-banner-slider .pagebuilder-slider .slick-list .slick-slide .banner-style .pagebuilder-slide-wrapper .pagebuilder-overlay .pagebuilder-poster-content>div {
        max-width: 100% !important;
    }

    div#html-body .section-banner-slider .pagebuilder-slider .slick-list .slick-slide .banner-style .pagebuilder-slide-wrapper .pagebuilder-overlay .pagebuilder-poster-content>div p:first-child {
        margin-bottom: 20px;
    }

    div#html-body .section-banner-slider .pagebuilder-slider .slick-list .slick-slide .banner-style .pagebuilder-slide-wrapper .pagebuilder-overlay .pagebuilder-poster-content>div p:first-child span {
        font-size: 35px !important;
    }

    div#html-body .section-banner-slider .pagebuilder-slider .slick-list .slick-slide .banner-style .pagebuilder-slide-wrapper .pagebuilder-overlay .pagebuilder-poster-content>div p:not(:first-child) span {
        font-size: 14px !important;
    }

    div#html-body .section-banner-slider .pagebuilder-slider .slick-dots {
        padding: 14px 0 !important;
    }

    div#html-body .section-banner-slider .pagebuilder-slider .slick-prev {
        left: 10px !important;
    }

    div#html-body .section-banner-slider .pagebuilder-slider .slick-next {
        right: 10px !important;
    }

    div#html-body .section-banner-slider .pagebuilder-button-primary {
        height: 40px !important;
        font-size: 14px !important;
    }

    div#html-body .section-how-to-shop {
        padding: 20px !important;
        margin: 0 !important;
        width: 100%;
    }

    div#html-body .section-how-to-shop .row-full-width-inner .pagebuilder-column-group {
        padding-bottom: 0 !important;
        padding-top: 0;
        flex-wrap: wrap;
    }

    div#html-body .section-how-to-shop .row-full-width-inner .pagebuilder-column-group .pagebuilder-column {
        padding: 20px 40px !important;
        width: 100%;
        display: flex !important;
        align-items: center;
    }

    div#html-body .section-how-to-shop .pagebuilder-column-group:first-child .pagebuilder-column {
        padding: 0 !important;
    }

    div#html-body .section-how-to-shop > .row-full-width-inner {
        position: relative;
        padding-bottom: 55px;
    }
    div#html-body .section-how-to-shop .pagebuilder-column-group {
        position: unset;
    }
    div#html-body .section-how-to-shop .pagebuilder-column-group:first-child .pagebuilder-column:last-child {
        width: auto;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
    }
    #html-body .section-how-to-shop .pagebuilder-button-primary {
        font-size: 14px;
        height: 35px;
        padding: 0 10px;
        min-width: 150px;
    }

    #html-body .section-how-to-shop .row-full-width-inner > .pagebuilder-column-group:first-child > .pagebuilder-column:first-child h2 {
        font-size: 18px;
        text-align: center;
        display: block;
        width: 100%;
        margin-bottom: 10px;
    }

    #html-body .section-how-to-shop .row-full-width-inner > .pagebuilder-column-group:last-child {
        flex-direction: row;
        flex-wrap: nowrap !important;
        padding: 0;
    }

    #html-body .section-how-to-shop .row-full-width-inner .pagebuilder-column-group:last-child .pagebuilder-column {
        padding: 20px 10px !important;
        flex-direction: column;
        gap: 0;
    }

    #html-body .section-how-to-shop .row-full-width-inner .pagebuilder-column-group:last-child .pagebuilder-column h3 {
        font-size: 12px;
        line-height: normal;
        margin-top: 10px;
        text-align: center;
    }

    div#html-body .section-shop-by .pagebuilder-column {
        min-width: 360px !important;
        width: 100% !important;
        padding: 22px !important;
        min-height: 360px !important;
        align-items: self-start !important;
    }

    div#html-body .section-shop-by .pagebuilder-column.left-wrapper>*,
    div#html-body .section-shop-by .pagebuilder-column.right-wrapper>* {
        display: block;
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    div#html-body .section-shop-by .pagebuilder-column h2 {
        font-size: 40px !important;
        margin: 0 0 10px !important;
        max-width: 250px !important;
    }

    div#html-body .section-shop-by .pagebuilder-column p {
        font-size: 14px !important;
        margin-bottom: 14px !important;
    }

    div#html-body .section-new-products,
    div#html-body .section-best-seller {
        padding: 40px 22px !important;
    }

    div#html-body .section-new-products .row-full-width-inner,
    div#html-body .section-best-seller .row-full-width-inner {
        padding: 0 !important;
    }

    div#html-body .section-exclusive-offers {
        padding: 55px 24px !important;
        background-position: center !important;
        min-height: 0 !important;
        margin: 22px !important;
    }

    div#html-body .section-exclusive-offers>.pagebuilder-column-group .pagebuilder-column {
        padding: 30px 14px !important;
        border-radius: 25px;
    }

    div#html-body .section-exclusive-offers>.pagebuilder-column-group .pagebuilder-column h2 {
        font-size: 40px !important;
    }

    div#html-body .section-exclusive-offers>.pagebuilder-column-group .pagebuilder-column p {
        font-size: 14px !important;
        margin-bottom: 10px !important;
    }

    div#html-body .section-join-seller {
        padding: 20px 22px !important;
    }

    div#html-body .section-join-seller .row-full-width-inner .pagebuilder-column-group .pagebuilder-column {
        min-height: 315px !important;
        padding: 22px !important;
    }

    div#html-body .section-join-seller .row-full-width-inner .pagebuilder-column-group .pagebuilder-column:first-child {
        border-radius: 25px !important;
    }

    div#html-body .section-join-seller .row-full-width-inner .pagebuilder-column-group .pagebuilder-column:not(:first-child) {
        display: none !important;
    }

    div#html-body .section-join-seller .row-full-width-inner .pagebuilder-column-group .pagebuilder-column h2 {
        font-size: 35px !important;
        margin: 0 0 10px;
    }

    div#html-body .section-join-seller .row-full-width-inner .pagebuilder-column-group .pagebuilder-column p {
        font-size: 18px !important;
        max-width: 400px;
        margin: 0 auto 14px !important;
    }

    div#html-body .section-join-seller .row-full-width-inner pagebuilder-button-primary {
        height: 40px !important;
        font-size: 14px !important;
        padding: 0 32px !important;
    }

    div#html-body .section-featured-seller {
        padding: 40px 22px 0 !important;
    }

    div#html-body .section-featured-seller .pagebuilder-column-group {
        width: 100% !important;
        display: block !important;
        box-sizing: border-box;
    }

    div#html-body .section-featured-seller .pagebuilder-column-group .pagebuilder-column {
        width: 50% !important;
        display: inline-block !important;
    }

    div#html-body .section-featured-seller .pagebuilder-column-group .pagebuilder-column img {
        width: 100% !important;
    }

    div#html-body #maincontent .block-new-products {
        padding: 40px 22px 0;
    }

    div#html-body .section-shop-by .pagebuilder-column.right-wrapper>h2,
    div#html-body .section-shop-by .pagebuilder-column.left-wrapper>h2 {
        font-size: 18px !important;
        max-width: 140px !important;
        margin-bottom: 20px !important;
    }
}

/* Highlight product section  */

#html-body .section-product-highlight {
    padding: 30px 0;
}
@media(min-width: 1025px) {
    #html-body .section-product-highlight > .pagebuilder-column-group:nth-of-type(2) {
        justify-content: space-between;
        line-height: 0;
    }
    #html-body .section-product-highlight > .pagebuilder-column-group:nth-of-type(2) .slick-slider {
        margin-right: -8px;
    }
}

#html-body .section-product-highlight .pagebuilder-column-group:first-child {
    margin-bottom: 15px;
}
@media(min-width: 1200px) {
    #html-body > div[data-content-type="row"] > .section-flashsale {
        margin-bottom: 30px;
    }
}
@media(max-width: 767px) {
    #html-body .section-information.special-promo {
        margin-bottom: 10px;
    }
    #html-body .section-product-highlight {
        padding: 15px 0;
    }
    #html-body .section-product-highlight.popular-categories {
        padding: 15px 0 25px 0;
    }
    #html-body .section-product-highlight.best-seller .pagebuilder-column-group:first-child,
    #html-body .section-product-highlight.special-price .pagebuilder-column-group:first-child,
    #html-body .section-product-highlight.new-product .pagebuilder-column-group:first-child {
        margin-bottom: 10px;
    }
    #html-body .section-flashsale {
        min-height: 180px;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        margin-bottom: 10px !important;
    }
}

#html-body .section-product-highlight .pagebuilder-column-group:first-child .pagebuilder-column {
    flex-direction: row !important;
    align-items: center;
}

#html-body .section-product-highlight .pagebuilder-column-group:first-child .pagebuilder-column h3 {
    font-size: 25px;
    margin: 0;
    font-weight: 700;
}

#html-body .section-product-highlight .pagebuilder-column-group:first-child .pagebuilder-column button {
    font-size: 14px;
    padding: 0;
    margin: 0 0 0 15px;
    font-weight: 400;
    color: #f58732;
    background: none;
    text-transform: none;
    height: 12px;
    margin-bottom: -5px;
}
@media(max-width: 767px) {
    #html-body .section-product-highlight .pagebuilder-column-group:first-child .pagebuilder-column button {
        margin-bottom: 0;
    }
}

#html-body .section-product-highlight .pagebuilder-column-group:last-child .pagebuilder-column.banner-column {
    width: 270px !important;
    justify-content: center;
    background: #D5EAFB;
    border-radius: 15px;
    height: 98%;
}

#html-body .section-product-highlight .pagebuilder-column-group:last-child .pagebuilder-column.banner-column img {
    max-height: 340px;
}

#html-body .section-product-highlight .pagebuilder-column-group:last-child .pagebuilder-column.product-column .block-content {
    margin-left: -70px;
}

#html-body .section-information {
    border-radius: 15px;
}

#html-body .section-information .pagebuilder-column h2 {
    font-size: 48px;
    line-height: 1.2;
    margin: 0;
    font-weight: 700;
}

#html-body .section-information.special-promo .pagebuilder-column h2 {
    color: #f58732;
}
@media(max-width: 1280px) and (min-width: 1199px) {
    #html-body .section-information.special-promo,
    #html-body .section-flashsale {
        margin-right: 15px !important;
        margin-left: 15px !important;
        width: auto !important;
    }
}

#html-body .section-information .pagebuilder-column p {
    font-size: 20px;
    margin: 5px 0 30px;
    line-height: 1.2;
}

#html-body .section-information.join-distributor .pagebuilder-column h2,
#html-body .section-information.join-distributor .pagebuilder-column p {
    color: #FFFFFF;
}

#html-body .section-information .pagebuilder-column button span {
    font-weight: 500;
    text-transform: capitalize;
}

#html-body .section-product-highlight.popular-categories {
    position: relative;
}

#html-body .section-product-highlight.popular-categories::after {
    content: '';
    height: 1px;
    width: 150vw;
    background: #D5EAFB;
    position: absolute;
    bottom: 0;
    left: -50vw;
}

#html-body .section-product-highlight.popular-categories .pagebuilder-column-group:last-child .pagebuilder-column {
    display: flex;
    flex-direction: row;
}
@media(max-width: 1023px) and (min-width: 768px) {
    #html-body .section-product-highlight.popular-categories .pagebuilder-column-group:last-child .pagebuilder-column {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        grid-gap: unset !important;
        gap: unset !important;
        width: auto;
        margin-left: -4px;
        margin-right: -4px;
    }
    #html-body .section-product-highlight.popular-categories .pagebuilder-column-group:last-child .pagebuilder-column > div {
        width: calc(25% - 8px) !important;
        margin: 0 4px 8px 4px;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
	#html-body .section-product-highlight.popular-categories .pagebuilder-column-group:last-child .pagebuilder-column {
        gap: 5px;
    }
}

@media (min-width: 1024px) {
    #html-body .section-product-highlight.popular-categories .pagebuilder-column-group:last-child .pagebuilder-column {
        gap: 15px;
    }
}

#html-body .section-product-highlight.popular-categories .pagebuilder-column-group:last-child .pagebuilder-column div,
#html-body .section-product-highlight.popular-categories .pagebuilder-column-group:last-child .pagebuilder-column button {
    width: 100%;
    display: block;
}

#html-body .section-product-highlight.popular-categories .pagebuilder-column-group:last-child .pagebuilder-column button {
    box-shadow: 0 0 4px rgb(0,0,0,0.2);
    background: #FFFFFF;
    min-height: 150px;
    color: #414048;
    border-radius: 15px;
    font-size: 14px;
    text-transform: capitalize;
}

#html-body .section-product-highlight.popular-categories .pagebuilder-column button::before {
    margin-bottom: 8px;
    display: block;
    background-size: 50px 50px;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    margin: auto auto 10px;
}

#html-body .section-product-highlight.popular-categories .icon-distributor button::before {
    content: '';
    background-image: url('../../public/assets/img/category-distributor.svg');
}

#html-body .section-product-highlight.popular-categories .icon-cosmetic button::before {
    background-image: url('../../public/assets/img/category-cosmetic.svg');
    content: '';
}

#html-body .section-product-highlight.popular-categories .icon-device button::before {
    background-image: url('../../public/assets/img/category-device.svg');
    content: '';
}

#html-body .section-product-highlight.popular-categories .icon-medicine button::before {
    background-image: url('../../public/assets/img/category-medicine.svg');
    content: '';
}

#html-body .section-product-highlight.popular-categories .icon-herbal button::before {
    background-image: url('../../public/assets/img/category-herbal.svg');
    content: '';
}

#html-body .section-product-highlight.popular-categories .icon-vaccine button::before {
    background-image: url('../../public/assets/img/category-vaccine.svg');
    content: '';
}

#html-body .section-product-highlight.popular-categories .icon-otc button::before {
    background-image: url('../../public/assets/img/category-otc.svg');
    content: '';
}

#html-body .section-product-highlight.popular-categories .icon-all button::before {
    background-image: url('../../public/assets/img/category-all.svg');
    content: '';
}

#html-body div.section-flashsale {
    border-radius: 20px;
    padding: 40px 60px;
    max-width: 1280px;
    margin: 0 auto;
}

#html-body .section-flashsale h2 {
    color: #FFFFFF;
    font-size: 48px;
    line-height: 1;
    margin-bottom: 0;
}

#html-body .section-flashsale h2 + div p {
    color: #FFFFFF;
    font-size: 20px;
}

#html-body .section-flashsale .pagebuilder-column + .pagebuilder-column {
    align-self: center;
}


@media (max-width: 767px) {
    #html-body .section-flashsale .pagebuilder-button-primary {
        height: 30px;
        font-size: 12px;
        text-transform: capitalize;
    }
    #html-body .section-product-highlight.popular-categories .pagebuilder-column-group:last-child .pagebuilder-column {
        display: grid;
        grid-template-columns: 23% 23% 23% 23%;
        grid-gap: 3%;
    }

    #html-body .section-product-highlight.popular-categories .pagebuilder-column-group:last-child .pagebuilder-column button {
        font-size: 12px;
        line-height: normal;
        min-height: 0;
        margin-bottom: 3%;
        padding: 12px 3px;
        border-radius: 8px;
        box-shadow: 0 0 4px rgb(0,0,0,0.15);
    }
    #html-body .section-product-highlight.popular-categories .pagebuilder-column-group:last-child .pagebuilder-column button > .MuiButton-label{
        min-height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #html-body .section-product-highlight.popular-categories .pagebuilder-column button::before {
        background-size: contain;
        width: 35px;
        height: 35px;
    }

    #html-body .section-information,
    #html-body .section-information .pagebuilder-column {
        min-height: 200px !important;
        background-position: center !important;
    }

    #html-body .section-information .pagebuilder-column {
        padding: 30px 0;
    }

    #html-body .section-information .pagebuilder-column h2 {
        font-size: 27px;
    }

    #html-body .section-information .pagebuilder-column p {
        font-size: 14px;
    }
    
    #html-body .section-information .pagebuilder-column button,
    #html-body .section-information .pagebuilder-column .pagebuilder-button-primary {
        font-size: 12px;
        height: 30px;
        padding: 0 15px;
    }

    #html-body div.section-flashsale {
        padding: 22px;
        width: 100%;
    }
    
    #html-body .section-flashsale .pagebuilder-column-group {
        flex-direction: column;
    }

    #html-body .section-flashsale .pagebuilder-column {
        width: 100%;
    }

    #html-body .section-flashsale .pagebuilder-column + .pagebuilder-column > div {
        text-align: center;
        margin-top: 12px;
    }

    #html-body .section-flashsale h2 {
        font-size: 27px;
        text-align: center;
        margin-bottom: 5px;
    }

    #html-body .section-flashsale h2 + div p {
        font-size: 14px;
        text-align: center !important;
        font-weight: 600;
    }
    
}

/* Banner Sldier */

#html-body .section-banner-slider {
    width: 100% !important;
    padding: 0 !important;
    display: block !important;
    max-width: 100% !important;
}

#html-body .section-banner-slider .pagebuilder-slide-wrapper {
    background-repeat: no-repeat;
    height: 26.5vw;
    background-size: cover;
}

#html-body .section-banner-slider .swiper-slide {
    width: 100% !important;
}

#html-body .section-banner-slider .swiper-pagination {
    bottom: 15px;
}

#html-body .section-banner-slider .swiper-pagination .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
    background-color: #FFFFFF;
    opacity: 1;
    border: 1px solid #FFFFFF;
}

#html-body .section-banner-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #f58732;
}

#html-body .section-banner-slider .swiper-button-prev,
#html-body .section-banner-slider .swiper-button-next {
    height: 28px;
    width: 28px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.25);
}
@media (max-width: 767px) {
    #html-body .section-banner-slider .swiper-button-prev,
    #html-body .section-banner-slider .swiper-button-next {
        display: none;
    }
}

#html-body .section-banner-slider .swiper-button-prev::after,
#html-body .section-banner-slider .swiper-button-next::after {
    color: #ffffff;
    font-size: 18px;
    opacity: 1;
}

#html-body .section-banner-slider .swiper-button-prev {
    left: 2rem;
}

#html-body .section-banner-slider .swiper-button-next {
    right: 2rem;
}

@media (max-width: 767px) {
    #html-body .section-banner-slider .pagebuilder-slide-wrapper {
        height: 100vw !important;
    }
}

/* PRODUCT WIDGET SLIDER  */

.section-product-highlight {

}

.section-product-highlight .slick-slider .slick-list .item-product > div {
    background: #FFFFFF;
    padding: 10px;
}

.section-product-highlight .slick-slider .slick-list .item-product {
    padding-left: 8px;
    padding-right: 8px;
}

.section-product-highlight .slick-slider .slick-list .item-product .req-register,
.section-product-highlight .slick-slider .slick-list .item-product .req-login,
.section-product-highlight .slick-slider .slick-list .item-product .product-nie {
    display: none !important;
}

.section-product-highlight .slick-slider .slick-list .item-product .product-name {
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
}
@media (max-width: 767px) {
    .section-product-highlight .slick-slider .slick-list .item-product .product-name {
        font-size: 12px;
    }
}

.section-product-highlight .product-column .carousel .arrow {
    width: 30px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 100px;
    color: #42929D;
    box-shadow: 0 4px 14px -5px rgba(0,0,0,0.5);
    font-size: 16px;
    top: calc(50% - 15px) !important;
}

.section-product-highlight .product-column .carousel .slick-prev,
.section-product-highlight .product-column .carousel .slick-next {
    width: 30px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 100px;
    color: #42929D;
    box-shadow: 0 4px 14px -5px rgba(0,0,0,0.5);
    font-size: 0;
    z-index: 1;
}

.section-product-highlight .product-column .carousel .slick-prev::before,
.section-product-highlight .product-column .carousel .slick-next::before {
    content: '\e967';
    font-family: 'icomoon';
    color: #42929D;
    display: block;
    font-size: 8px;
    font-weight: 800;
}

.section-product-highlight .product-column .carousel .slick-prev {
    transform: rotate(90deg);
    left: 0;
}

.section-product-highlight .product-column .carousel .slick-next {
    transform: rotate(-90deg);
    right: 0;
}

.section-product-highlight .product-column .carousel .slick-disabled {
    display: none !important;
}

.section-product-highlight .product-column .carousel .arrow.right {
    right: -5px;
}

.section-product-highlight .product-column .carousel .arrow.left {
    left: -5px;
}

.section-product-highlight .slick-list .slick-track {
    height: 340px;
    overflow-y: hidden;
    margin: 0;
}

.section-product-highlight .slick-list .slick-track > .slick-slide:not(:first-child) {
    background-color: #FFFFFF;
}

.section-product-highlight .slick-list .slick-track > .slick-slide:nth-child(2) {
    /* background-color: orange; */
    background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
}

.section-product-highlight .slick-list .slick-track .slick-slide .MuiTypography-body1[class*="manufacture"] {
    display: none;
}

@media (max-width: 445px) {
    #html-body .section-product-highlight .widget-product-list .carousel .slick-list .slick-track .slick-slide > div {
        width: 82% !important;
        margin: auto;
        max-height: none;
    }
}

@media (min-width: 385px) and (max-width: 445px) {
    #html-body .section-product-highlight .widget-product-list .carousel .slick-list .slick-track .slick-slide .item-product > div {
        max-height: none !important;
    }
}

/* BANNER COLOR */

.banner-column.color-red {
    background: linear-gradient(to bottom, #FA2E2C, #ffb1af) !important;
}

.banner-column.color-blue {
    background: linear-gradient(to bottom, #06AEC9, #b3eaea) !important;
}

.banner-column.color-green {
    background: linear-gradient(to bottom, #0D9816, #b3e9b8) !important;
}

.banner-column.color-orange {
    background: linear-gradient(to bottom, #F58732, #FFD47F) !important;
}

.banner-column.color-yellow {
    background: linear-gradient(to bottom, #FFD501, #fae996) !important;
}

.banner-column.color-black {
    background: linear-gradient(to bottom, #414048, #97969d) !important;
}

.banner-column.color-gray {
    background: linear-gradient(to bottom, #CCCCCC, #EEEEEE) !important;
}